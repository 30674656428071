@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1 {
  font-weight: 700;
  font-size: 19px;
  letter-spacing: 0.84px;
}
h2 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.71px;
}
h3 {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.8px;
}

p {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.62px;
  margin-bottom: 10px;
}

ul.bulletPoints li {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.62px;
}

ul.bulletPoints li:not(:last-child) {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 31px;
    letter-spacing: 1.38px;
  }
  h2 {
    font-size: 19px;
    letter-spacing: 0.84px;
  }
  h3 {
    font-size: 19px;
    letter-spacing: 0.84px;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.71px;
  }

  ul.bulletPoints li {
    font-size: 16px;
    letter-spacing: 0.71px;
  }

  ul.bulletPoints li:not(:last-child) {
    margin-bottom: 20px;
  }
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */
